import React, { useState } from 'react'
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import axios from 'axios'


const NewClient = () => {

  const [toast, setToast] = useState(false);

  function sendNewClient(event) {
    event.preventDefault();
    var i;
    var obj = '';
    for (i=3; i<8;i++) {
      if (event.target[i].checked) {
        obj = event.target[i].id;
      }
    }

    const name = event.target[0].value;
    const email = event.target[1].value;
    const phone = event.target[2].value;
    const message='New Client : '+name+'.\n Email : '+email+'.\n Phone : '+phone+'.\n Investment Objective : '+obj

    let payload = new FormData();
    payload.append("subject","New Client Contact Alert");
    payload.append("to",['wsullivan@twm-asn.com','jstock@twm-asn.com']);
    payload.append("message",message);

    axios.post('https://backend.tenet-wealth.com/v1/sendEmail',
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
          }
      }
    ).then(res => {
      setToast(true);
    })

  }




  return (
    <div className="container">
    <Card bg='light' className="text-center shadow-lg mb-5 mt-5">
      <Card.Header>
        <Card.Title  style={{"fontWeight":"bold","fontSize":"200%"}}>Connect with an Investment Advisor</Card.Title>
        <h6>Turn your financial goals into a reality</h6>
      </Card.Header>
    <br/>
    <Card.Body>
        <Form onSubmit={e => sendNewClient(e)}>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Name</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Name" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Email address</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg " required type="email" placeholder="Enter email" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Phone Number</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" placeholder="Optional" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Investment Objective</Form.Label>
            <div className="col-sm-4" style={{"display":"block","textAlign":"left"}}>
              <Form.Check
                   type="radio"
                   label="Income"
                   name="formHorizontalRadios"
                   id="income"
                 />
                 <Form.Check
                   type="radio"
                   label="Capital Preservation"
                   name="formHorizontalRadios"
                   id="Capital Preservation"
                 />
                 <Form.Check
                   type="radio"
                   label="Long Term Growth"
                   name="formHorizontalRadios"
                   id="Long Term Growth"
                 />
                 <Form.Check
                   type="radio"
                   label="Aggressive Growth"
                   name="formHorizontalRadios"
                   id="Aggresive Growth"
                 />
                 <Form.Check
                   type="radio"
                   label="Other"
                   name="formHorizontalRadios"
                   id="Other"
                 />
              </div>
            </div>

          <Card.Footer>
            <Button variant="outline-primary" type="submit">
              Connect
            </Button>
          </Card.Footer>
        </Form>
        </Card.Body>

      </Card>

      <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
        position: 'absolute',
        top: 25,
        right: 10,
      }}>
        <Toast.Header>
          <strong className="mr-auto">Message Sent!</strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body>Thank you for your interest. An advisor will reach out with you shortly. Have a great day!</Toast.Body>
      </Toast>

    </div>

  )
}

export default NewClient;
