import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Navigation from './components/Navigation';
import NewClient from './components/newClient.js';
import About from './components/about.js';
import Strategies from './components/strategies.js';
import SignUp from './components/signUp.js';
import Annuity from './components/annuity.js';
import Team from './components/team.js';
import Footer from "./components/footer.js";

class App extends Component {
  render() {
    return(
      <BrowserRouter>
        <div>
          <Navigation/>
            <Switch>
            <Route path="/" component={Home} exact/>
            <Route path='/team' component= {Team}/>
            <Route path='/newClient' component={NewClient}/>
            <Route path='/about' component={About}/>
            <Route path='/signUp' component={SignUp}/>
            <Route path='/EIA' component={Annuity}/>

          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
