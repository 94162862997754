import React from 'react'

const Footer = () => {

  return(
  <footer class="footer" >
  <div className="container-flex bg-dark">
    <div className="container" >
      <span style={{"color":"white"}}>
      <br/>
        TENET WEALTH MANAGEMENT, A MEMBER OF ADVISORY SERVICES NETWORK, LLC, <br/>
        1039 BROAD ST, CAMDEN, SC, 29020, UNITED STATES <br/>803-781-1034
      </span>
      <br/><br/>

      <span className="text-muted">
Registered Representatives of Tenet Wealth Management are registered with and offer securities through Kovack Securities, Inc., Member FINRA/SIPC. Kovack Securities Corporate Headquarters: www.kovacksecurities.com 800-711-4078.  Advisory services offered through Tenet Wealth Management, a member of Advisory Services Network, LLC. Headquarters: www.advservnet.com (770)-352-0449.  Kovack Securities and Advisory Services Network are not affiliated. Insurance products offered through various insurance carriers, none of which are affiliated with Kovack Securities, Inc. or Advisory Services Network, LLC. Individual registered representatives of Tenet Wealth Management are registered to offer securities in the following states: CO, FL, GA, KY, NC, NM, SC and TX. Individual advisory representatives of Tenet Wealth Management are registered to offer advisory services in NC, SC and TX.
<br/><br/>
      There are many factors that affect investment performance including,
       but not limited to, general economic and market conditions including market volatility.
        There can be no assurance that these factors will affect future investment performance
         in the same manner as historical performance. All investments involve a risk of loss.
          Individual performance may vary based on factors such as account size, contributions
           and withdrawals among other factors.
      </span>
    </div>
    </div>
  </footer>
)
}
export default Footer;
