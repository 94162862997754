import React from 'react';

const Navigation = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-sm navbar-dark" style={{"backgroundImage":"radial-gradient(circle,#969696,#1b1a1a)"}}>
        <div className="container">

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav nav-fill w-100">
              <li className="nav-item" style={{"font-family":"Roboto","fontSize":"150%","fontWeight":"bold"}}>
                <a className="navbar-brand" href="/ ">
                  <img src={process.env.PUBLIC_URL + '/twm.jpg'} className="rounded" style={{"height":"50px","width":"70px"}} alt=""/>
                </a>
              </li>
              <li className="nav-item" style={{"font-family":"Roboto","fontSize":"150%","fontWeight":"bold"}}>
                <a className="nav-link" style={{"color":"white"}} href="/about">About</a>
              </li>
              <li className="nav-item" style={{"font-family":"Roboto","fontSize":"150%","fontWeight":"bold"}}>
                <a className="nav-link" style={{"color":"white"}} href="/team">Our Team</a>
              </li>
              <li className="nav-item" style={{"font-family":"Roboto","fontSize":"150%","fontWeight":"bold"}}>
                <a className="nav-link" style={{"color":"white"}} href="/newClient">Contact Us</a>
              </li>
              <li className="nav-item" style={{"font-family":"Roboto","fontSize":"150%","fontWeight":"bold"}}>
                <a className="nav-link" style={{"color":"white"}} href="/signUp">Join Our Team</a>
              </li>
            </ul>
          </div>

        </div>
      </nav>
    </div>

  )
}
export default Navigation;
