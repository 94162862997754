import React, { useState } from 'react'
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'

const Annuity = () => {

  return(
    <div className='container'>


    </div>
  )



}
export default Annuity;
