import React from 'react'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck';
import Accordion from'react-bootstrap/Accordion';
import { Button } from 'react-bootstrap';

const Team = () => {


  const bgUrl=process.env.PUBLIC_URL + '/cave.png';
  return(
    <div className="container-flex" style={{"background-image" : "url("+bgUrl+")","background-size":"cover","height":"100%"}}>
      <div className="container">
      <br/><br/><br/>
      <CardDeck style={{"justifyContent":"center","width":"100%"}}>
      <Accordion defualtActiveKey="0">
        <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
          <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>Jonathan D. Fike</Card.Header>
          <Accordion.Toggle as={Card.Title} eventKey="0">
            <div style={{"text-align":"center"}}>
            <Button className="m-3" style={{"align":"center"}} variant="light">Learn more</Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
              <Card.Text>
                Jonathan's job is to make sure Tenet Wealth Management delivers on its tenets first. He empowers the Tenet Wealth Management team to find solutions that are are tailored to your individual situation. Jonathan also ensures that the firms collective expertise and the very best of it's collective knowledge, whether it be investments, risk management or estate planning, is utilized in each of your plans. <br/><br/>Jonathan leads by example; he has his own clients to serve. This keeps him in touch with both the clients needs as well as the needs of his team. To lead by example one must also live with the results of one's decisions.
              </Card.Text>
              <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
              <Card.Text>
                Chartered Financial Consultant (ChFC®)<br/><br/>
                Accredited Estate Planner (AEP®)<br/><br/>
                B.A. in Monetary Finance and Economics, University of North Carolina at Asheville
              </Card.Text>
              <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Community and Industry Affiliations</Card.Title>
              <Card.Text>
                Business Networking International - Kershaw Connectors Chapter - Past President<br/><br/>
                Columbia Estate Planning Council - Member
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defualtActiveKey="0">
        <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
          <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>Jeffrey L. Stock Jr.</Card.Header>
          <Accordion.Toggle as={Card.Title} eventKey="0">
            <div style={{"text-align":"center"}}>
            <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">

          <Card.Body>
            <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Portfolio Manager</Card.Title>
            <Card.Text>
              Jeffrey leads a team that uses an disciplined investment process that is data-driven, analytical and utilizes a global approach in regards to asset class and geography. They set asset allocations based upon your risk tolerance with the intent to optimize after-tax returns. Jeffrey leads the exhaustive research process on asset managers and individual companies and that process is in-depth, on-going and experiential. The research process is driven by the desire to find the best match concerning risk and return for each asset class.<br/><br/>

              Jeffrey works closely with your Tenet Wealth Management advisor and is always ready to explain how your portfolio is invested and the rationale behind those decisions.
            </Card.Text>
            <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
            <Card.Text>
              Chartered Financial Analyst (CFA)<br/><br/>
              MBA, University of South Carolina<br/><br/>
              B.A. in Music, University of North Texas - Cum Laude
            </Card.Text>
            <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Community and Industry Affiliations</Card.Title>
            <Card.Text>
              CFA Society of South Carolina - Board of Directors<br/><br/>

              CFA Institute<br/><br/>

              Carolina Wind Symphony - Board Member and Member<br/><br/>

              Carolina Brass Band - Member<br/><br/>

              Palmetto Concert Band - Member
            </Card.Text>
          </Card.Body>
          </Accordion.Collapse>
        </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>Craig C. Cochran</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
                <Card.Text>
                Each day, Craig focuses on helping to get you a step closer to your goals, and taking care of any speed bumps along the way. He's there to help with the daily decisions and challenges as they arise, as well as your long term mission. Craig’s ultimate goal is to provide clients with the opportunity for financial peace of mind. Craig is known for putting clients and their priorities first and working hard to find solutions that are well though out and executed.
                <br/><br/>
                Craig works with clients in all stages of their lives from entrepreneurs, business professionals to retirees. He helps to develop comprehensive financial plans that help them navigate through the choppy waters that life is made up of and helps them get to their home port.                 </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                  B.S.B.A. in Finance, University of South Carolina
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>William G. Sullivan</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Portfolio Manager</Card.Title>
                <Card.Text>
                William jumped into the business head first after graduating top of his class from Clemson in 2019. William's strong mathematical and statistical skills allow him to leverage quantitive tools with the goal of improving returns and reducing risk for his clients. His strong understanding of options and other derivatives allows for the addition of highly complex and speculative strategies that are usually reserved for hedge funds and other institutions. William
                loves to work with aggressive clients who are trying to achieve returns larger than typical benchmarks.
                  <br/><br/>
                  When William is not in the office, you will usually find him on the golf course. An experienced golfer, William led the Clemson Club golf team to back-to-back National Championships. He is currently a member at Columbia Country Club, his preferred place for business meetings.
                </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                  B.S. in Applied Mathematics, Clemson University - Summa Cum Laude<br/><br/>
                  Series 65 - FINRA<br/><br/>
                  Insurance Producer - Accident, Health & Life (South Carolina)<br/><br/>
                  William Sullivan is only registered with Advisory Services Network, LLC, and is unaffiliated with Kovack Securities, Inc.
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>W. Eddins Galloway, Jr.</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
                <Card.Text>
                Having played and coached a myriad of sports, Ed knows the importance of having a game plan and then modifying it as things change. He applies this same logic to the financial plans he helps to develop for his clients, centering them on “what if” scenarios. Considering all the possible outcomes up front makes it more likely long-term financial goals can be met, Ed says, even as life and your circumstances change. Ed enjoys getting to know you and your family and then applying our firm’s collective expertise to align your finances with the life you want.<br/><br/>
                Ed has over 30 years of experience and he utilizes that knowledge and experience to formulate complete financial plans to help his clients attain their financial goals.
                </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                  B.S. in Finance, Clemson University<br/><br/>
                   W. Eddins Galloway, Jr. is only registered with Advisory Services Network, LLC, and is unaffiliated with Kovack Securities, Inc.
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>H.A. Kallio (Kal)</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
                <Card.Text>
                For Kal, being your Tenet Wealth Management advisor means being your financial champion: helping you dodge pitfalls, seize opportunities and activate all the tools that can make a big difference in the long run, such as estate and tax planning.
                <br/><br/>
                Kal leverages his 21 years of experience when working with clients to help them define their goals and desires and then uses those to create a comprehensive financial plan designed to achieve them.
                </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                  B.S. Indiana Institute of Technology - Magna Cum Laude<br/><br/>
                  Certified Financial Planner (CFP)<br/><br/>
                  H.A. Kallio (KAL) is only registered with Advisory Services Network, LLC, and is unaffiliated with Kovack Securities, Inc.
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>Don Hunt</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
                <Card.Text>
                Visual, prioritize and analyze. This is what Don helps you do when it comes to your finances and by extension your entire life. He firmly believes that the financial decisions that you make today can greatly influence how you live life.
                <br/><br/>
                With over eighteen years of experience Don knows all the details that go into crafting an enduring yet flexible financial plan. Don has been married for thirty-five years and has three grown children.
                </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                  B.S.B.A. in Marketing, East Carolina University<br/><br/>
                Don Hunt is only registered with Advisory Services Network, LLC, and is unaffiliated with Kovack Securities, Inc.
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>Nick Papadea</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
                <Card.Text>
                Nick knows that getting to the finish line is not easy: careful planning and execution are key, as are adjustments along the way. Nick draws on his experience as a Vietnam War veteran who served in the Navy to shape his approach to financial planning. As an advisor Nick is methodical, strategic and keeps you focused on the goal – what you want to achieve.
                <br/><br/>
                With over 45 years of experience. Nick’s extensive experience throughout many market conditions enable him to help his clients form financial plans to address their specific financial objectives.
                </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                MBA, University of South Carolina<br/><br/>
                Nick Papadea is only registered with Advisory Services Network, LLC, and is unaffiliated with Kovack Securities, Inc.
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>Craig S. Schinke</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
                <Card.Text>
                While he has a broad range of clients, Craig often works with people who are experts in their fields. Whether your're a doctor, lawyer, business executive or entrepreneur, Craig works diligently to coordinate all aspects of your financial life so that you have a clear sense of where you're headed and real confidence in your financial decision making.
                </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                B.G.S Ohio University in Business and Design<br/><br/>
                Craig Schinke is only registered with Advisory Services Network, LLC, and is unaffiliated with Kovack Securities, Inc.
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defualtActiveKey="0">
          <Card className="bg-secondary text-white mb-3 mt-3" style={{"fontSize":"125%"}}>
            <Card.Header style={{"fontSize":"150%","textAlign":"center","fontWeight":"bold"}}>Brent N. Townsend</Card.Header>
            <Accordion.Toggle as={Card.Title} eventKey="0">
              <div style={{"text-align":"center"}}>
              <Button className="m-3" style={{"background":"white","align":"center"}} variant="light">Learn more</Button>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Wealth Advisor</Card.Title>
                <Card.Text>
                Brent works with clients on a gamut of financial issues, but what he truly loves is helping people manage life's transitions. Whether you've just sold your business, become and empty nester or looking for calm after a financial storm, Brent helps you develop an action plan. Entrepreneurs and others new to wealth will find his approach instrumental in helping to achieve their personal goals.
                <br/><br/>
                Brent doesn't leave any loose ends when putting together your wealth management plan. All aspects of your life are considered.
                </Card.Text>
                <Card.Title style={{"fontWeight":"bold","fontSize":"150%","color":"#38393d"}}>Designations and Education</Card.Title>
                <Card.Text>
                Chartered Financial Consultant (ChFC®)<br/><br/>
                Chartered Life Underwriter (CLU)
                </Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

      </CardDeck>
    </div>
    <br/>
    </div>


  )
}
export default Team;
