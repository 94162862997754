import React from 'react';

const Home = () => {
  const bgUrl= process.env.PUBLIC_URL + '/mountain.png';

    return (
      <div className="container-flex" style={{"background-image" : "url("+bgUrl+")","background-repeat":"no-repeat","background-position":"center","background-size":"cover", "height":"90vh","opacity":".9"}}>
           <h1 className="mb-3 ml-3 mr-3 mx-auto text-center" style={{"font-family":"Roboto","font-size":"calc(2em + 5vw)","text-shadow":"2px 2px 4px #ffffff"}}>Welcome to Tenet Wealth Management</h1>
           <p className="mx-auto text-center" style={{"font-family":"Roboto","font-size":"2em","color":"white","text-shadow":"2px 2px 4px #000000","font-style":"italic"}}>A Member of Advisory Services Network, LLC</p>
           <h3 className="m-5 mx-auto text-center" style={{"font-family":"Roboto","font-size":"calc(1em + 4vw)","text-shadow":"2px 2px 4px #ffffff"}}>Your climb to financial freedom starts here</h3>
           <p className="m-5 mx-auto text-center" style={{"font-family":"Roboto","font-size":"2em","text-shadow":"2px 2px 4px #ffffff"}}>Click above to learn how our team can improve your financial future</p>
       </div>
    );
}

export default Home;
