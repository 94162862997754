import React, { useState } from 'react'
import { Form, Button, Toast } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import axios from 'axios'
import ReactLoading from "react-loading";

const SignUp = () => {

  const [toast, setToast] = useState(false);
  const [loading,setLoading] = useState(false);

  function sendNewClient(event) {
    event.preventDefault();
    setLoading(true);
    var i;
    var obj = '';
    for (i=17; i<22;i++) {
      if (event.target[i].checked) {
        obj = event.target[i].id;
      }
    }
    const clientInfo = {
      'name' : event.target[0].value,
      'email' :event.target[1].value,
      'physicalAddress' : event.target[2].value,
      'mailingAddress' : event.target[3].value,
      'phone' : event.target[4].value,
      'birthday' : event.target[5].value,
      'ssn' : event.target[6].value,
      'birthplace' : event.target[7].value,
      'employer' : event.target[9].value,
      'position' : event.target[10].value,
      'employerAddress': event.target[11].value,
      'income' : event.target[12].value,
      'taxBracket' : event.target[13].value,
      'investNetWorth' : event.target[14].value,
      'liquidNetWorth' : event.target[15].value,
      'investmentObjective':obj,
      'primaryBene' : event.target[21].value,
      'contingentBene' : event.target[22].value,
    }
    var file= event.target[8].files[0];
    var otherFile = event.target[23].files[0];
    if (file){

      let payload = new FormData();


      payload.append('subject','New Client Sign Up')
      payload.append('to',['wsullivan@twm-asn.com','jstock@twm-asn.com'])
      payload.append('message',JSON.stringify(clientInfo,null,4))
      payload.append('file',file)
      if(otherFile){
        payload.append('otherFile',otherFile)
      }

      axios.post('https://backend.tenet-wealth.com/v1/sendEmail',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
            }
        }
      ).then(res => {
        setLoading(false);
        setToast(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })

    }
    else {
      const payload = {
        subject: 'New Client Alert',
        to: ['wsullivan@twm-asn.com','jstock@twm-asn.com'],
        //message: JSON.stringify(clientInfo,null,4)
        message:JSON.stringify(clientInfo,null,4),
      }
      axios.post('https://backend.tenet-wealth.com/v1/sendEmail',payload).then(res => {
        setToast(true);
      })

    }


  }


  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{"margin-top":"40vh"}}>
        <ReactLoading type="bars" color="navy" />
      </div>
    )
  }

  return (
    <div className="container" >
    <Card bg='light' className="text-center shadow-lg mb-5 mt-5">
      <Card.Header>
        <Card.Title style={{"fontWeight":"bold","fontSize":"200%"}}>Join Our Team</Card.Title>
        <h6>Fill out the form to become our newest client</h6>
      </Card.Header>
    <br/>
    <Card.Body>
        <Form onSubmit={e => sendNewClient(e)}>
        <div className="form-group row">
          <Form.Label className="col-sm-4 text-right" style={{"color":"navy","fontSize":"150%"}}>Personal Information</Form.Label>
        </div>
        <hr/>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Name</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Name" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Email address</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg " required type="email" placeholder="Enter email" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Physical Address</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Physical Address" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Mailing Address</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" placeholder="Mailing Address (Leave blank if same as above)" />
          </div>

          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Phone Number</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required type="tel" placeholder="Primary Phone Number" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Birthdate</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" type="date" required placeholder="Birthdate" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Social Security Number</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" type="password" style={{"text-security":"disc", "-webkit-text-security":"disc"}} required placeholder="SSN" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Birth Place</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Place of Birth (City, State)" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Picture of Drivers License</Form.Label>
              <Form.Control className="col" type="file" accept="image/*" required placeholder="Upload" />
          </div>


          <br/>
          <div className="form-group row">
            <Form.Label className="col-sm-4 text-right" style={{"color":"navy","fontSize":"150%"}}>Employer Information</Form.Label>
          </div>
          <hr/>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Current Employer</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Current Employer" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Current Position</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Position" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Employer Address</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Physical Address" />
          </div>
          <br/>
          <div className="form-group row">
            <Form.Label className="col-sm-4 text-right" style={{"color":"navy","fontSize":"150%"}}>Financial Information</Form.Label>
          </div>
          <hr/>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Prior Year Income</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg"  placeholder="Total Income in Prior Year" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Tax Bracket</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" placeholder="Tax Bracket Last Year (Optional)" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Investable Net Worth</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required placeholder="Total amount invested and money to be invested" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Liquid Net Worth</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" required  placeholder="Liquid Net Worth" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Investment Objective</Form.Label>
            <div className="col-sm-4" style={{"display":"block","textAlign":"left"}}>
              <Form.Check
                   type="radio"
                   label="Income"
                   name="formHorizontalRadios"
                   id="income"
                 />
                 <Form.Check
                   type="radio"
                   label="Capital Preservation"
                   name="formHorizontalRadios"
                   id="Capital Preservation"
                 />
                 <Form.Check
                   type="radio"
                   label="Long Term Growth"
                   name="formHorizontalRadios"
                   id="Long Term Growth"
                 />
                 <Form.Check
                   type="radio"
                   label="Aggressive Growth"
                   name="formHorizontalRadios"
                   id="Aggresive Growth"
                 />
                 <Form.Check
                   type="radio"
                   label="Other"
                   name="formHorizontalRadios"
                   id="Other"
                 />
              </div>
            </div>

          <br/>
          <div className="form-group row">
            <Form.Label className="col-sm-4 text-right" style={{"color":"navy","fontSize":"150%"}}>Beneficiaries</Form.Label>
          </div>
          <hr/>

          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Primary Beneficiary</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" placeholder="(Optional) Name, Relationship, Birthdate" />
          </div>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Contingent Beneficiary</Form.Label>
              <Form.Control className="col-sm-6 shadow-lg" placeholder="(Optional) Name, Relationship, Birthdate" />
          </div>
          <hr/>
          <div className="form-group row">
            <div className="col-sm-1">
            </div>
            <Form.Label className="col-sm-2 text-right">Other File Upload</Form.Label>
              <Form.Control className="col" type="file" placeholder="Upload File" />
          </div>

          <Card.Footer>
            <Button variant="outline-primary" type="submit">
              Submit
            </Button>
          </Card.Footer>
        </Form>
        </Card.Body>

      </Card>

      <Toast onClose={() => setToast(false)} show={toast} delay={5000} autohide  style={{
        position: 'fixed',
        top: 25,
        right: 10,
      }}>
        <Toast.Header>
          <strong className="mr-auto">Form Sent!</strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body>Thank you for your interest. An advisor will reach out to you shortly. Have a great day!</Toast.Body>
      </Toast>

    </div>

  )
}

export default SignUp;
