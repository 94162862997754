import React from 'react'
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';

const About = () => {
  const bgUrl= process.env.PUBLIC_URL + '/triangle.png';



  return (
    <div className="container-flex" style={{"background-image" : "url("+bgUrl+")","background-repeat":"no-repeat","background-position":"center","background-size":"cover", "height":"100%"}}>
    <div className="container" style= {{"font-family": " sans-serif","align":"center"}}>
      <br/><br/>
      <Card bg='secondary' text='white' className="text-center justify-content-center mx-auto mt-4" style={{"auto":"true","fontSize":"125%"}}>
      <Card.Header>Full-Service Wealth Management</Card.Header>
        <Card.Body>
          <Card.Text>
        We provide financial planning, investment management, and risk management, all under one roof. We work with you and your family to help you envision and simplify your entire financial life. Our wealth management strategies are geared to meet your current needs, help you prioritize and achieve your goals throughout your lifetime, and safeguard your wishes and legacy for future generations.
          </Card.Text>
        </Card.Body>
      </Card>

          <Card bg='secondary' text='white' className="text-center justify-content-center mx-auto mt-4" style={{"auto":"true","fontSize":"125%"}}>
          <Card.Header>Strategic Financial Planning</Card.Header>
            <Card.Body>
              <Card.Text>
              Tenet Wealth Management believes in strategic financial planning. As investment advisor representatives, we are uniquely positioned among wealth management firms to try to provide truly comprehensive and integrated financial solutions.
              <br/><br/>
              Your customized financial plan can include tax aware planning for this year and beyond; investment management; retirement planning; estate planning, including family and charitable giving. Plus all other strategies specific to your situation, such as college funding or stock options compensation.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card bg='secondary' text='white' className="text-center justify-content-center mx-auto mt-4" style={{"auto":"true","fontSize":"125%"}}>
          <Card.Header>Trust and Estate Planning</Card.Header>
            <Card.Body>
              <Card.Text>
              Tenet Wealth Management advisors also help you address how you want your wealth handled well into the future. This may include maximizing wealth transfer between generations; leaving a philanthropic legacy through a charitable annuity, charitable trust or foundation; or working to ensure your heirs have the resources they need.
              <br/><br/>
              We have helped hundreds of families determine the most effective trust and estate planning strategies for their situation — and we continue as their investment managers and advisors long after the trusts are created.              </Card.Text>
            </Card.Body>
          </Card>

          <Card bg='secondary' text='white' className="text-center justify-content-center mx-auto mt-4" style={{"auto":"true","fontSize":"125%"}}>
          <Card.Header>Investment Management</Card.Header>
            <Card.Body>
              <Card.Text>
              At Tenet Wealth Management your investment strategy is based on your goals and your unique situation.
              <br/><br/>
              A Tenet investment plan is tax-aware and tailored to your needs. Asset allocation is optimized for after-tax return, given your risk and income preferences. Asset managers and companies within each of your investment categories are thoroughly researched by us and closely monitored. Our tax-aware investing strategies include both tax-exempt and taxable options.
              <br/><br/>
              The bottom line is with Tenet Wealth Management, you get much more than investment advice. You get an advocate for your life, your wealth and your legacy.
              </Card.Text>

            </Card.Body>
          </Card>

        <Card bg='secondary' text='white' className="text-center justify-content-center mx-auto mt-4" style={{"auto":"true","fontSize":"125%"}}>
          <Card.Header>Speak with a Specialist</Card.Header>
          <Card.Body>
            <Card.Text>
              Fill out the form below to see how our strategies can fit in your portfolio.
            </Card.Text>
            <Button href="/newClient" variant="outline-primary" style={{"background":"white"}}>Contact Form</Button>

          </Card.Body>

        </Card>

        <br/> <br/><br/>
    </div>
    </div>
  )
}

export default About;
